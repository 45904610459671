/* eslint-disable */
import request from './request';

/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function auth(data: any) {
  return request('/apiService/mechanism/login', {
    data,
    method: 'post',
  });
}
/**
 * 注册
 *
 * @param data
 * @returns
 */
export async function register(data: any) {
  return request('/apiService/mechanism/register', {
    data,
    method: 'post',
  });
}

/**
 * 通过短信验证码重设密码
 *
 * @param data
 * @returns
 */
export async function resetPasswordByVerifyCode(data: any) {
  return request('/apiService/mechanism/password/reset', {
    data,
    method: 'post',
  });
}

/**
 * 发送短信验证码（注册）
 *
 * @param data
 * @returns
 */
export async function smsSendOnRegister(data: any) {
  return request('/apiService/mechanism/verifyCode/register/send', {
    data,
    method: 'post',
  });
}

/**
 * 发送短信验证码（重置密码）
 *
 * @param data
 * @returns
 */
export async function smsSendOnResetPassword(data: any) {
  return request('/apiService/mechanism/verifyCode/resetPassword/send', {
    data,
    method: 'post',
  });
}

/**
 * 获取个人资料
 *
 * @param data
 * @returns
 */
export async function profile(data: any) {
  return request('/apiService/mechanism/profile', {
    data,
    method: 'get',
  });
}

/**
 * 修改个人资料
 *
 * @param data
 * @returns
 */
export async function profileUpdate(data: any) {
  return request('/apiService/mechanism/profile/update', {
    data,
    method: 'post',
  });
}

/**
 * 分页获取参数配置列表
 *
 * @param data
 * @returns
 */
export async function parameterPaginate(data: any) {
  return request('/apiService/mechanism/parameter/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数配置详情
 *
 * @param data
 * @returns
 */
export async function parameterDetail(data: any) {
  return request('/apiService/mechanism/parameter/detail', {
    data,
    method: 'get',
  });
}

/**
 * 创建参数配置
 *
 * @param data
 * @returns
 */
export async function parameterCreate(data: any) {
  return request('/apiService/mechanism/parameter/create', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置名称
 *
 * @param data
 * @returns
 */
export async function parameterUpdateName(data: any) {
  return request('/apiService/mechanism/parameter/update/name', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置状态
 *
 * @param data
 * @returns
 */
export async function parameterUpdateStatus(data: any) {
  return request('/apiService/mechanism/parameter/update/status', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置配置
 *
 * @param data
 * @returns
 */
export async function parameterUpdateConfig(data: any) {
  return request('/apiService/mechanism/parameter/update/config', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数默认配置
 *
 * @param data
 * @returns
 */
export async function parameterUpdateDefaultConfig(data: any) {
  return request('/apiService/mechanism/parameter/update/defaultConfig', {
    data,
    method: 'post',
  });
}

/**
 * 删除参数配置
 *
 * @param data
 * @returns
 */
export async function parameterDelete(data: any) {
  return request('/apiService/mechanism/parameter/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取所有模型列表
 *
 * @param data
 * @returns
 */
export async function llmModelList(data: any) {
  return request('/apiService/mechanism/llmModel/list', {
    data,
    method: 'get',
  });
}

/**
 * 分页获取项目列表
 *
 * @param data
 * @returns
 */
export async function projectPaginate(data: any) {
  return request('/apiService/mechanism/project/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取项目详情
 *
 * @param data
 * @returns
 */
export async function projectDetail(data: any) {
  return request('/apiService/mechanism/project/detail', {
    data,
    method: 'get',
  });
}

/**
 * 创建修改参数配置
 *
 * @param data
 * @returns
 */
export async function projectUpdateOrCreate(data: any) {
  return request('/apiService/mechanism/project/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 修改项目名称
 *
 * @param data
 * @returns
 */
export async function projectUpdateName(data: any) {
  return request('/apiService/mechanism/project/update/name', {
    data,
    method: 'post',
  });
}

/**
 * 修改项目选中的键
 *
 * @param data
 * @returns
 */
export async function projectUpdateSelectedKeys(data: any) {
  return request('/apiService/mechanism/project/update/selectedKeys', {
    data,
    method: 'post',
  });
}

/**
 * 删除项目
 *
 * @param data
 * @returns
 */
export async function projectDelete(data: any) {
  return request('/apiService/mechanism/project/delete', {
    data,
    method: 'post',
  });
}

/**
 * 终止评估
 *
 * @param data
 * @returns
 */
export async function projectStop(data: any) {
  return request('/apiService/mechanism/project/stop', {
    data,
    method: 'post',
  });
}

/**
 * 取消排队
 *
 * @param data
 * @returns
 */
export async function projectCancelQueue(data: any) {
  return request('/apiService/mechanism/project/cancelQueue', {
    data,
    method: 'post',
  });
}
/**
 * 上传文件
 *
 * @param data
 * @returns
 */
export async function projectUpload(data: any) {
  return request('/apiService/mechanism/project/upload', {
    data,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
/**
 * 修改文件地址
 *
 * @param data
 * @returns
 */
export async function projectUpdateFileUrl(data: any) {
  return request('/apiService/mechanism/project/update/fileUrl', {
    data,
    method: 'post',
  });
}

/**
 * 修改项目评估结果的参数设置
 *
 * @param data
 * @returns
 */
export async function projectResultParameterConfigUpdate(data: any) {
  return request(
    '/apiService/mechanism/project/result/update/parameterConfig',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 开始评估
 *
 * @param data
 * @returns
 */
export async function projectEvaluate(data: any) {
  return request('/apiService/mechanism/project/evaluate', {
    data,
    method: 'post',
  });
}

/**
 * 计算评估需要花费的金额
 *
 * @param data
 * @returns
 */
export async function projectCalcEstimatePrice(data: any) {
  return request('/apiService/mechanism/project/estimatePrice', {
    data,
    method: 'post',
  });
}
/**
 * 继续评估
 *
 * @param data
 * @returns
 */
export async function projectContinueEvaluate(data: any) {
  return request('/apiService/mechanism/project/result/continue', {
    data,
    method: 'post',
  });
}

/**
 * 重新评估
 *
 * @param data
 * @returns
 */
export async function projectReEvaluate(data: any) {
  return request('/apiService/mechanism/project/reEvaluate', {
    data,
    method: 'post',
  });
}

/**
 * 修改结果值的某一项
 *
 * @param data
 * @returns
 */
export async function projectResultUpdateResultItem(data: any) {
  return request('/apiService/mechanism/project/result/update/resultItem', {
    data,
    method: 'post',
  });
}

/**
 * 修改结果值的某一个评估结果
 *
 * @param data
 * @returns
 */
export async function projectResultUpdateResultItemByFuncItem(data: any) {
  return request(
    '/apiService/mechanism/project/result/update/resultItemByFuncItem',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 获取结果列表
 *
 * @param data
 * @returns
 */
export async function projectResultList(data: any) {
  return request('/apiService/mechanism/project/result/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取结果详情
 *
 * @param data
 * @returns
 */
export async function projectResultDetail(data: any) {
  return request('/apiService/mechanism/project/result/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取钱包流水列表
 *
 * @param data
 * @returns
 */
export async function walletRecordPaginate(data: any) {
  return request('/apiService/mechanism/wallet/record/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取机构钱包
 *
 * @param data
 * @returns
 */
export async function walletDetail(data: any) {
  return request('/apiService/mechanism/wallet/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取系统配置
 *
 * @param data
 * @returns
 */
export async function systemSettingGet(data: any) {
  return request('/apiService/mechanism/systemSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取项目评审的价格列表
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewPriceList(data: any) {
  return request('/apiService/mechanism/project/expertReview/priceList', {
    data,
    method: 'get',
  });
}

/**
 * 创建评审
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewCreate(data: any) {
  return request('/apiService/mechanism/project/expertReview/create', {
    data,
    method: 'post',
  });
}

/**
 * 获取可开票的流水
 *
 * @param data
 * @returns
 */
export async function invoiceValidWalletRecordList(data: any) {
  return request('/apiService/mechanism/invoice/validWalletRecord/list', {
    data,
    method: 'get',
  });
}

/**
 * 申请开票
 *
 * @param data
 * @returns
 */
export async function invoiceApply(data: any) {
  return request('/apiService/mechanism/invoice/apply', {
    data,
    method: 'post',
  });
}

/**
 * 获取开票记录
 *
 * @param data
 * @returns
 */
export async function invoicePaginate(data: any) {
  return request('/apiService/mechanism/invoice/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 获取开票详情
 *
 * @param data
 * @returns
 */
export async function invoiceDetail(data: any) {
  return request('/apiService/mechanism/invoice/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取机构配置
 *
 * @param data
 * @returns
 */
export async function mechanismSettingGet(data: any) {
  return request('/apiService/mechanism/mechanismSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取支付二维码
 *
 * @param data
 * @returns
 */
export async function mechanismWalletRechargeQrCodeGet(data: any) {
  return request('/apiService/mechanism/wallet/recharge/qrcode', {
    data,
    method: 'post',
  });
}

/**
 * 获取OSS上传凭证
 *
 * @param data
 * @returns
 */
export async function commonOssStsTokenGet(data: any) {
  return request('/apiService/mechanism/oss/stsToken', {
    data,
    method: 'get',
  });
}

/**
 * 获取项目结果的参数设置
 *
 * @param data
 * @returns
 */
export async function projectResultParameterSettingGet(data: any) {
  return request('/apiService/mechanism/project/result/parameterSetting', {
    data,
    method: 'get',
  });
}

/**
 * 修改项目结果的参数设置
 *
 * @param data
 * @returns
 */
export async function projectResultParameterSettingSet(data: any) {
  return request('/apiService/mechanism/project/result/parameterSetting/set', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数默认值配置信息
 *
 * @param data
 * @returns
 */
export async function parameterDefaultSettingGet(data: any) {
  return request('/apiService/mechanism/parameter/defaultConfig', {
    data,
    method: 'get',
  });
}

/**
 * 设置参数默认值配置信息
 *
 * @param data
 * @returns
 */
export async function parameterDefaultSettingSet(data: any) {
  return request('/apiService/mechanism/parameter/defaultConfig/set', {
    data,
    method: 'post',
  });
}

/**
 * 获取导出列表
 *
 * @param data
 * @returns
 */
export async function projectResultExportList(data: any) {
  return request('/apiService/mechanism/project/result/export/list', {
    data,
    method: 'get',
  });
}

/**
 * 导出结果
 *
 * @param data
 * @returns
 */
export async function projectResultExport(data: any) {
  return request('/apiService/mechanism/project/result/export', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrList(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/pdr/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrUpdateOrCreate(data: any) {
  return request(
    '/apiService/mechanism/parameter/dataSource/pdr/updateOrCreate',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 删除基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrDelete(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/pdr/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrDetail(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/pdr/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFList(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/f/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFUpdateOrCreate(data: any) {
  return request(
    '/apiService/mechanism/parameter/dataSource/f/updateOrCreate',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 删除基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFDelete(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/f/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFDetail(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/f/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据项列表
 *
 * @param data
 * @returns
 */
export async function parameterItemList(data: any) {
  return request('/apiService/mechanism/parameter/item/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据项
 *
 * @param data
 * @returns
 */
export async function parameterItemUpdateOrCreate(data: any) {
  return request('/apiService/mechanism/parameter/item/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除参数数据项
 *
 * @param data
 * @returns
 */
export async function parameterItemDelete(data: any) {
  return request('/apiService/mechanism/parameter/item/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据项详情
 *
 * @param data
 * @returns
 */
export async function parameterItemDetail(data: any) {
  return request('/apiService/mechanism/parameter/item/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据配置列表
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigList(data: any) {
  return request('/apiService/mechanism/parameter/item/config/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据配置
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigUpdateOrCreate(data: any) {
  return request('/apiService/mechanism/parameter/item/config/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除参数数据配置
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigDelete(data: any) {
  return request('/apiService/mechanism/parameter/item/config/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据配置详情
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigDetail(data: any) {
  return request('/apiService/mechanism/parameter/item/config/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据PDR配置列表
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigList(data: any) {
  return request('/apiService/mechanism/parameter/item/pdrConfig/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据PDR配置
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigUpdate(data: any) {
  return request('/apiService/mechanism/parameter/item/pdrConfig/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据配置详情
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigDetail(data: any) {
  return request('/apiService/mechanism/parameter/item/pdrConfig/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceList(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceDetail(data: any) {
  return request('/apiService/mechanism/parameter/dataSource/detail', {
    data,
    method: 'get',
  });
}

/**
 * 导入项目结果（解析内容）
 *
 * @param data
 * @returns
 */
export async function projectResultImportParse(data: any) {
  return request('/apiService/mechanism/project/result/import/parse', {
    data,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * 导入项目结果
 *
 * @param data
 * @returns
 */
export async function projectResultImport(data: any) {
  return request('/apiService/mechanism/project/result/import', {
    data,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
