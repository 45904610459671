import React, { useEffect, useState } from 'react';
import Footer from '@/components/Footer';
import Logo from '@/assets/logo-full.jpg';
import LoginForm from './form';
import LoginBanner from './banner';
import styles from './style/index.module.less';
import { PageTypeLogin, PageTypeRegister } from './constants';
import RegisterForm from './registForm';
import ForgetForm from './forgetForm';
import { useHistory } from 'react-router-dom';
import LoginBackground from '@/assets/login-background.png';
import LoginLogo from '@/assets/login-logo.png';
import { Image } from '@arco-design/web-react';
function Login() {
  const [pageType, setPageType] = useState(PageTypeLogin);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
  }, []);

  useEffect(() => {
    const pgType = +params.get('pageType');
    setPageType(pgType || PageTypeLogin);
  }, [window.location.search]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${LoginBackground})`,
      }}
    >
      <div className={styles.banner}>
        <div className={styles['banner-inner']}>
          <Image src={LoginLogo} width={800} preview={false} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-logo']}>
          <Image src={Logo} width={250} preview={false} />
        </div>
        <div className={styles['content-inner']}>
          {pageType == PageTypeLogin ? (
            <LoginForm
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          ) : pageType == PageTypeRegister ? (
            <RegisterForm
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          ) : (
            <ForgetForm
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          )}
        </div>
        {/* <div className={styles.footer}>
          <Footer />
        </div> */}
      </div>
    </div>
  );
}
Login.displayName = 'LoginPage';

export default Login;
