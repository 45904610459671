export function formatNumber(
  number: any,
  showDecimal: boolean,
  divideNumber: number
): string {
  if (isNaN(parseFloat(number))) {
    number = 0;
  }

  number = parseFloat(number) / divideNumber; // 将分转换为元

  if (showDecimal) {
    return number.toFixed(2);
  } else {
    return Math.floor(number).toString();
  }
}

// 保留小数位
export function toFixedNumber(num, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

// 计算百分比
export function calcPercent(
  numerator: number,
  denominator: number,
  showDecimal: boolean
): number {
  if (isNaN(numerator) || isNaN(denominator)) {
    return 0;
  }

  const percent = (numerator / denominator) * 100;
  let progress = Math.floor(percent);
  if (showDecimal) {
    progress = parseFloat(percent.toFixed(2));
  }
  return progress > 100 ? 100 : progress;
}

export function calcMinute(dateStr: string) {
  // 创建一个表示当前时间的Date对象
  const now = new Date();

  // 创建一个表示"2023/10/25 14:00:00"的Date对象
  const targetDate = new Date(dateStr);

  // 计算两个时间的差值（毫秒）
  const diff = targetDate.getTime() - now.getTime();

  // 将差值转换为分钟
  const diffInMinutes = Math.round(diff / 1000 / 60);
  return diffInMinutes;
}

export function regexMobile(mobile: string) {
  return /^1[1-9]\d{9}$/.test(mobile);
}

export function regexLengthNumber(number: string, length: number) {
  return new RegExp(`^\\d{${length}}$`).test(number);
}
