import {
  Form,
  Input,
  Button,
  Space,
  Message,
  Image,
} from '@arco-design/web-react';
import {
  IconCodeSquare,
  IconLock,
  IconUser,
} from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import styles from './style/index.module.less';
import { auth, register, smsSendOnRegister } from '@/request/api';
import { PageTypeLogin } from './constants';
import { regexLengthNumber, regexMobile } from '@/utils/util';
import Logo from '@/assets/logo.jpg';
const CaptchaTimeout = 60;

export default function RegisterForm(props: {
  onTypeChange?: (arg: number) => void;
}) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  // 禁止发送验证码的标识
  const [forbidSendSms, setForbidSendSms] = useState(false);
  // 倒计时
  const [countdown, setCountdown] = useState(CaptchaTimeout);
  // 验证码loading
  const [smsLoading, setSmsLoading] = useState(false);

  function afterLoginSuccess(params, accessToken) {
    // 记录登录状态
    localStorage.setItem('token', accessToken);
    // 跳转首页
    window.location.href = '/';
  }

  function regist(params) {
    setErrorMessage('');
    setLoading(true);
    register({ ...params, verifyCode: +params.verifyCode })
      .then((resp) => {
        if (resp.code === 200) {
          props.onTypeChange && props.onTypeChange(PageTypeLogin);
          Message.success('注册成功，请登录');
          // afterLoginSuccess(params, resp.data.token);
        } else {
          setErrorMessage(resp.message || '登录失败，请稍后重试');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function sendSMS() {
    if (forbidSendSms) {
      return;
    }
    if (smsLoading) return;
    try {
      await form.validate(['account']);
      setSmsLoading(true);
      await smsSendOnRegister({
        account: form.getFieldValue('account'),
      });
      // 将按钮禁用
      console.warn('还是走到我le ?');
      setForbidSendSms(true);
    } catch (e) {
      console.error(e);
    }
    setSmsLoading(false);
  }

  function onSubmitClick() {
    form.validate().then((values) => {
      regist(values);
    });
  }

  useEffect(() => {
    // 开始倒计时
    let intervalInstance = null;
    if (forbidSendSms) {
      setCountdown(CaptchaTimeout);
      let currentTimeout = CaptchaTimeout;
      intervalInstance = setInterval(() => {
        currentTimeout--;
        if (currentTimeout <= 0) {
          setForbidSendSms(false);
          intervalInstance && clearInterval(intervalInstance);
        }
        setCountdown(currentTimeout);
      }, 1e3);
    }
  }, [forbidSendSms]);

  return (
    <div className={styles['login-form-wrapper']}>
      {/* <div
        className={styles['login-form-title']}
        style={{ textAlign: 'center' }}
      >
        <Image src={Logo} width={200} preview={false} />
      </div> */}
      <div className={styles['login-form-title']} style={{ textAlign: 'left' }}>
        账号注册
      </div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        form={form}
        initialValues={{ account: '', password: '' }}
      >
        <Form.Item
          field="account"
          required
          rules={[
            {
              validator(value, cb) {
                if (!regexMobile(value)) {
                  return cb('请输入正确的11位手机号');
                }
                return cb();
              },
            },
          ]}
        >
          <Input
            className={styles['login-form-input']}
            prefix={<IconUser style={{ color: '#1d4e84', fontSize: 20 }} />}
            maxLength={11}
            placeholder="请输入手机号"
          />
        </Form.Item>
        <Form.Item
          field="name"
          required
          rules={[
            {
              required: true,
              type: 'string',
              message: '请输入公司名称',
            },
          ]}
        >
          <Input
            prefix={<IconUser style={{ color: '#1d4e84', fontSize: 20 }} />}
            placeholder="请输入公司名称"
            className={styles['login-form-input']}
          />
        </Form.Item>
        <Form.Item
          field="verifyCode"
          required
          rules={[
            {
              validator(value, cb) {
                if (!regexLengthNumber(value, 6)) {
                  return cb('请输入6位数字验证码');
                }
                return cb();
              },
            },
          ]}
        >
          <Input
            className={styles['login-form-input']}
            maxLength={6}
            // loading={smsLoading}
            prefix={
              <IconCodeSquare style={{ color: '#1d4e84', fontSize: 20 }} />
            }
            // onSearch={() => {
            //   sendSMS();
            // }}
            addAfter={
              <Button
                type="text"
                loading={smsLoading}
                style={{
                  color: '#1d4e84',
                }}
                onClick={() => {
                  sendSMS();
                }}
              >
                {forbidSendSms ? `${countdown}秒后重试` : '发送验证码'}
              </Button>
            }
            // searchButton={
            //   forbidSendSms ? `${countdown}秒后重试` : <span>发送验证码</span>
            // }
            placeholder="请输入验证码"
          />
        </Form.Item>
        <Form.Item
          field="password"
          required
          rules={[
            {
              required: true,
              type: 'string',
              minLength: 6,
              maxLength: 20,
              message: '请输入6-20位密码',
            },
          ]}
        >
          <Input.Password
            className={styles['login-form-input']}
            maxLength={20}
            prefix={<IconLock style={{ color: '#1d4e84', fontSize: 20 }} />}
            placeholder="请输入密码"
          />
        </Form.Item>
        <Form.Item
          field="repeatPassword"
          dependencies={['password']}
          required
          rules={[
            {
              validator: (v, cb) => {
                if (!v) {
                  return cb('请重复输入密码');
                } else if (form.getFieldValue('password') !== v) {
                  return cb('两次密码不一致');
                }
                cb(null);
              },
            },
          ]}
        >
          <Input.Password
            className={styles['login-form-input']}
            maxLength={20}
            prefix={<IconLock style={{ color: '#1d4e84', fontSize: 20 }} />}
            placeholder="确认密码"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          }}
        ></div>
        <Space size={16} direction="vertical">
          <Button
            type="primary"
            long
            onClick={onSubmitClick}
            loading={loading}
            style={{
              backgroundColor: '#1d4e84',
            }}
          >
            注册
          </Button>
          <Button
            type="text"
            long
            style={{ color: '#1d4e84' }}
            onClick={() => {
              props.onTypeChange && props.onTypeChange(PageTypeLogin);
            }}
          >
            返回登录页
          </Button>
        </Space>
      </Form>
    </div>
  );
}
